<template>
  <div id="app" class="container">
    <div class="header"><my-header></my-header></div>
    <div class="mains">
      <div class="w1200">
        <!-- <search-item></search-item> -->
        <router-view></router-view>
        <!-- <img src="@img/banner.png" alt="" class="banner" /> -->
        <img :src="banner" alt="" class="banner" v-if="banner" />
      </div>
      <my-footer></my-footer>
    </div>
   
    <div class="fix2">
      <div class="fixitem" @click="openpage('/fbxq')">
        <img src="@img/add.png" alt="" />
        <div>发布需求</div>
      </div>
       <div class="fixitem item2"  @click="getkefu()">
        <img src="@img/kefu.png" alt="" />
        <div>联系客服</div>
      </div>
       <!--slot="reference"  -->
    <!--   <el-popover
      placement="left"
      trigger="click"
      >
      <div class="">
       <div>
        电话：{{kefu}}
       </div> 
       <div>
        
        <img src="@img/wxkefu.jpg" alt=""  width="150" />
        <div class="textcenter " >
          扫码咨询
        </div>
       </div> 
      </div>
      <div class="fixitem" slot="reference">
        <img src="@img/kefu.png" alt="" />
        <div>联系客服</div>
      </div>
    </el-popover>  -->
    </div>
    <Login v-if="loginDialogVisible"></Login>
    <Regist v-if="registDialogVisible"></Regist>
    <Forget v-if="psdDialogVisible"></Forget>
  </div>
</template>

<script>
import myHeader from "@c/common/header.vue";
import myFooter from "@c/common/footer.vue";
// import searchItem from "@c/common/searchitem.vue";
import Login from "@c/common/login.vue";
import Regist from "@c/common/regist.vue";
import Forget from "@c/common/forget.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "App",
  components: {
    myHeader,
    myFooter,
    Login,
    Regist,
    Forget,
    // searchItem
   
  },
  data() {
    return {
      backBottom: 80,
      banner:""
    };
  },
  computed: {
    ...mapState([
      "isLogin",
      "userInfo",
      "loginDialogVisible",
      "registDialogVisible",
      "psdDialogVisible",
      "kefu",
    ]),
  },
  mounted() {
    if (this.$utils.getItem("isLogin") == 1) {
      this.setLogin(1);
      // console.log(this.$utils.getItemObj("userInfo"))
      this.setUserInfo(this.$utils.getItemObj("userInfo"));
    }
      this.$http.footerinfo().then(res=>{
         this.banner=res.data.bottom_ad;
      }) 
  },
  methods: {
    ...mapMutations([
      "setLogin",
      "setUserInfo",
      "setLoginDialog",
      "setRegistDialog",
      "setkefu",
    ]),
    openpage(path, id) {
      if(this.isLogin==1){
        this.$router.push({
          path,
          query: {
            id,
          },
        });
      }else{
        this.setLoginDialog(true);
      }
    },
    getkefu() {
      if(this.isLogin==1){
        this.$store.dispatch("setkefu")
      }else{
        this.setLoginDialog(true);
      }
     
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
.el-notification__group{
  margin-left: 0!important;
}
.el-notification__content{
  margin-top: 0!important;
}
.el-notification{
  padding: 0!important;
}
.el-notification__closeBtn {
  
    top: 6px!important;
    right: 5px!important;
    color: #fff!important;
    font-size: 30px!important;
}
.lxkf{
  position: relative;
  width: 230px;
  background: #FFFFFF;
  box-shadow: 0px 2px 6px 0px rgba(153,153,153,0.5);
  border-radius: 10px;
  .kftitle{
      background: #1879F5;
      font-size: 16px;
      border-radius: 10px 10px 0px 0px;
      color: #FFFFFF;
       text-align: center;
      line-height: 40px;
  }
  .divider{
    background: #999999;
    opacity: 0.5;
    width: 100%;
    height: 1px;
   margin: 5px 0;
  }
  .line{
    padding: 5px 0;
    text-align: center;
    display: flex;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    img{
      margin-right: 5px;
    }
  }
  .phone{
   color: #1879F5;
   padding: 5px;
  }
  .wechat{
    color: #6CB37A;
     padding: 5px;
  }
  .code{
    display: block;
  }
}
.flexy{
  flex-direction: column;
}
.black{
  color: #333;
}
.bold{
  font-weight: bold;
}
.kefu {
  color: rgb(73, 110, 232);
  font-size: 20px;
  font-weight: bold;
}
.header {
  padding: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}
.mains {
  padding-top: 80px;
}
.banner {
  width: 100%;
  height: 140px;
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
}
.fix2 {
  position: fixed;
  top: 40%;
  right: 10px;
   border-radius: 4px;

  .fixitem {
    width: 70px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #666;
   border-radius: 4px 4px 0 0 ;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(153, 153, 153, 0.5);
    cursor: pointer;
    &.item2{
      border-radius: 0 0 4px 4px;
    }
    img {
      width: auto;
      height: 24px;
      object-fit: contain;
      margin-bottom: 5px;
    }
  }
}
</style>
