import axios from "axios"
import { Message } from "element-ui"

const instance = axios.create({
    baseURL: "",
    time: 1000 * 30
})
instance.defaults.withCredentials = true
instance.interceptors.request.use(
    config => {
        if (sessionStorage.getItem("token")) {
            config.headers.token = sessionStorage.getItem("token");
        }
        return config

    }, error => {
        Message.error({ message: "请求超时!" })
        return Promise.reject(error)
    }
)
// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    const status = response.status
    if (status === 200) {
        return Promise.resolve(response)
    }  else {
        return Promise.reject(response)
    }
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
})

const ajaxMethod = ["get", "post"]
const api = {}
ajaxMethod.forEach(method => {
    // 数组取值的两种方式
    api[method] = function (url, data, config) {
        return new Promise(function (resolve, reject) {
            instance[method](url, data, config)
                .then(response => {
                    if (response.status === 200) {
                        if(response.data.code===401){
                            Message.error({ message: "请先登录!" })
                            return false
                        }else{
                            resolve(response.data)
                        }
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
})

export default api
