 import Vue from "vue"
import Vuex from "vuex"
import  _fuc from "@/utils/utils"
import  getApi from "@/apis/http"
import { Notification } from "element-ui"
Vue.use(Vuex)
/* const defaultuserinfo={
 avatar: "http://rianfw.dev.zhangxinkeji.com/assets/img/default-avatar.png"
company_name: "领悟信息"
is_bind_wechat: "未绑定"
is_cert: "1"
is_cert_text: "未认证"
mobile: "18896582510"
nickname: "日安用户Vijb7H"
   }; */
const store = new Vuex.Store({
    state: {
       isLogin:2, // 是否登录,1登录，2未登录
       userInfo:null,
       loginDialogVisible: false, // 登录弹窗显示与隐藏
       registDialogVisible: false, // 注册弹窗显示与隐藏
       psdDialogVisible:false,//忘记密码
       kefu:""
    },
    mutations: {
       setLogin (state, val = 0) {
           state.isLogin = val
           _fuc.setItem("isLogin",val)
       },
       setUserInfo (state, val = {}) {
           if(Object.getOwnPropertyNames(val).length==0){
            state.userInfo=null
            _fuc.removeItem("userInfo")
           }else{
            state.userInfo= val
            _fuc.setItemObj("userInfo", state.userInfo)
           }
       },
       setLoginDialog (state, val = null) {
           state.loginDialogVisible = val
       },
       setRegistDialog (state, val = null) {
        state.registDialogVisible = val
      },
      setPsdDialog (state, val = null) {
        state.psdDialogVisible = val
      },
      changekefu(state,val){
        
      }
    },
    actions:{
      setkefu(context){
        var img=require("@img/wxkefu.jpg");
        var dianhua=require("@img/dianhua.png");
        var weixin=require("@img/weixin-2.png");
        if (context.state.kefu) {
          Notification({
            // title: "客服电话",
            dangerouslyUseHTMLString: true,
            message:`<div class="lxkf">
            <div class="kftitle">联系客服</div>
            <div class="phone">
              <div class="line">
                 <img src="${dianhua}" alt="" width="28"/>
                <div>
                  7×24小时电话管家
                </div>
              </div>
               <div class="line bold">${context.state.kefu}</div>
            </div>
            <div class="divider"></div>
            <div class="wechat">
                <div class="line">
                 <img src="${weixin}" alt="" />
                <div>
                  微信客服
                </div>
              </div>
               <div class="line bold">（9:00~18:00）</div>
               <div class="line flexy">
               <img src="${img}" alt=""  width="150" class="code" />
                  <div class="word black">请使用微信扫码</div>
               </div>
            </div>
        </div>`,
            duration:50000
          });
        } else {
          getApi.cstel().then((res) => {
          
            context.state.kefu=res.data.tel;
            Notification({
              // title: "客服电话",
              dangerouslyUseHTMLString: true,
              message:`<div class="lxkf">
              <div class="kftitle">联系客服</div>
              <div class="phone">
                <div class="line">
                <img src="${dianhua}" alt="" width="28"/>
                  <div>
                    7×24小时电话管家
                  </div>
                </div>
                 <div class="line bold">${context.state.kefu}</div>
              </div>
              <div class="divider"></div>
              <div class="wechat">
                  <div class="line">
                  <img src="${weixin}" alt="" />
                  <div>
                    微信客服
                  </div>
                </div>
                 <div class="line bold">（9:00~18:00）</div>
                 <div class="line flexy">
                 <img src="${img}" alt=""  width="150" class="code"/>
                    <div class="word black">请使用微信扫码</div>
                 </div>
              </div>
          </div>`,
            duration:50000
            });
          });
        }
      }
    }
   
    // this.$store.dispatch('Login',this.form)
    // this.$store.commit("changeSpace",)
})


export default store