import api from "./instance"

export default {
    // 修改头像和昵称
    changeinfo({nickname="",avatar=""}){
        return api.post("api/member/editBaseInfo",{nickname,avatar})
    },
    // 地区三级
    getareas(){
        return api.get("api/mis/areas")
    },
    // 认证业务方向
    getywfx(){
        return api.get("api/auth/company_fs_data?type=ywfx")
    },
     // 认证行业类型
    gethylx(){
        return api.get("api/auth/company_fs_data?type=hylx")
    },
    //认证顶部流程图
    topimagebyweb(){
        return api.get("api/auth/topimagebyweb")
    },
    // 企业认证
    authcompany(formdata){
        return api.post("api/auth/company",formdata)
    },
     // 个人认证
    authperson(formdata){
        return api.post("api/auth/person",formdata)
    },
    sendsms({mobile="",event=""}){
        return api.get(`api/login/sendsms?mobile=${mobile}&event=${event}`,{})
    },
    register({mobile="",sms_code="",password="",repassword="",nickname=""}){
        return api.post("api/login/register",{mobile,sms_code,password,repassword,nickname})
    },
    login({mobile="",password=""}){
        return api.post("api/login/account",{mobile,password})
    },
    getuserinfo(){
        return api.get("api/member")
    },
    quickloginbymobile({mobile="",sms_code=""}){
        return api.post("api/login/quickloginbymobile",{mobile,sms_code})
    },
    forget({mobile="",sms_code="",password="",repassword=""}){
        return api.post("api/login/forget",{mobile,sms_code,password,repassword})
    },
    aboutus(){
        return api.get("api/mis/aboutus")
    },
    yszc(){
        return api.get("agreement/yszc")
    },
    yhfwxy(){
        return api.get("agreement/yhfwxy")
    },
    feedback({content="",images="",tel=""}){
        return api.post("api/member/feedback",{content,images,tel})
    },
    // 认证协议
    agreement(){
        return api.get("api/auth/agreement")
    },
    // 客服电话
    cstel(){
        return api.get("api/mis/cstel")
    },
    location_city(){
        return api.get("api/home/location_city")
    },

    // 服务流程
    ad_home(){
        return api.get("api/home/sqbyweb")
    },
    // 首页轮播图，bind_type 可能存在两种值: product, news 分别对应跳转 产品 和 新闻, 关联bind_id，?type=web
    banner_home(){
        return api.get("api/banner?type=web")
    },
    // 施工案例
    case_home(){
        return api.get("api/home/case?type=mp")
    },
   
    // 维修快报
    maintenance(){
        return api.get("api/home/maintenance?limit=10")
    },
    news_home(){
        return api.get("api/home/news?limit=2")
    },
    footerinfo(){
        return api.get("api/home/extenddatabyweb")
    },
    // 服务分类
    listbyweb(){
        return api.get("api/category/listbyweb")
    },
    getproductlist({cate_id="",offset=0,limit=8,keyword=""}){
        return api.get(`api/product/listbyweb?cate_id=${cate_id}&offset=${offset}&limit=${limit}&keyword=${keyword}`)
    },
    productdetail({id=""}){
        return api.get(`api/product/row?id=${id}`) 
    },
    // 收藏产品
    productfav({id=""}){
        return api.get(`api/product/fav?id=${id}`) 
    },
    // 收藏列表
    favbyproduct({offset=0,limit=9}){
        return api.get(`api/member/favbyproduct?is_web=yes&offset=${offset}&limit=${limit}`)
    },
    // 服务推荐关联的产品
    fwtjlist({offset=0,limit=9}){
        return api.get(`api/home/sqlist?offset=${offset}&limit=${limit}`)   
    },
     // 服务推荐
     sq({offset=0,limit=4,keyword=""}){
        return api.get(`api/home/sq?offset=${offset}&limit=${limit}&keyword=${keyword}`)
    },
    // 资讯活动
    newslist({offset=0,limit=8,keyword=""}){
        return  api.get(`api/news/list?offset=${offset}&limit=${limit}&keyword=${keyword}`)   
    },
    newsdetail({id=""}){
        return api.get(`api/news/row?id=${id}`) 
    },
    demandimg(){
        return api.get("api/demand/topimagebyweb") 
    },
    demandsubmit(formdata){
        return api.post("api/demand/submit",formdata)
    },
    // 需求类型
    demandcates(){
        return api.get("api/demand/cates") 
    },
    getdemandproduct({category_id=""}){
        return api.get(`api/demand/product?category_id=${category_id}`) 
    },
    demandlist({offset=0,limit=5,status="all"}){
        return api.get(`api/demand/list?offset=${offset}&limit=${limit}&status=${status}`) 
    },
    demandinitdata({product_id=""}){
        return api.get(`api/demand/initdata?product_id=${product_id}&lat=&lng=`) 
    }




} 
