/* eslint-disable no-mixed-spaces-and-tabs */
<template>
  <div class="header">
    <div class="wrapper w1660 flex align-center">
      <div class="flex flex-row align-center">
        <!-- <img src="../../assets/logo-s.png" alt="" class="logo" /> -->
         <img class="logo" src="@img/logo.png" />
        <!-- <span class="title">日安管家</span> -->
      </div>

      <ul class="nav">
        <li
          :class="[menuActive.indexOf(item.path) === 0 ? 'is-active' : '']"
          v-for="item in menuList"
          :key="item.path"
          @click="selectMenu(item.path)"
        >
          {{ item.name }}
        </li>
      </ul>
    <div class="rightinfo">
        <div class="mobile" @click="scrollToBottom()">
          <img src="../../assets/mobile.png" alt="" />
          <div class="word">移动版</div>
        </div>

        <div :class="isLogin==1 ? 'user-avatar' : 'login'">
          <div class="logined" v-if="isLogin==1">
            <el-dropdown
              placement="bottom"
              @command="userMenuHandler"
              trigger="click"
            >
            <div class="user">
                <div class="headimg">
                  <!-- <img src="@img/headimg.png" alt="" /> -->
                   <img :src="userInfo.avatar" alt="" />
                </div>
                <div class="name">{{ userInfo.nickname }}</div>
                <img src="@img/arrow-d2.png" alt="" class="arrow" />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="user">
                  <span class="usericon icon1"></span>个人中心
                </el-dropdown-item>
                <el-dropdown-item command="set" >
                  <span class="usericon icon2"></span>修改密码
                </el-dropdown-item>
                <el-dropdown-item command="quit">
                  <span class="usericon icon3"></span>退出登录
                </el-dropdown-item>
              </el-dropdown-menu>
            
            </el-dropdown>
          </div>
          <span class="login-btn" v-else>
            <span class="regist" @click="registDialog">注册</span>
            <span class="login" @click="loginDialog">登录</span>
          </span>
        </div>   
    </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "myHeader",
  components: {},
  data() {
    return {
      menuList: [
        {
          name: "首页",
          path: "/index",
        },
        {
          name: "全部服务",
          path: "/qbfw",
        },
        {
          name: "资讯活动",
          path: "/zxhd",
        },
        {
          name: "服务推荐",
          path: "/fwtj",
        },
        {
          name: "服务案例",
          path: "/sgal",
        },
        {
          name: "个人中心",
          path: "/my",
        },
        {
          name: "关于我们",
          path: "/aboutus",
        },
      ],
    };
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapState(["isLogin", "userInfo"]),
    menuActive() {
      return this.$route.path;
    },
  },
   watch: {
    keyVal(newVal, oldVal) {
      this.keyVal = newVal;
    },
  },
  mounted(){
   
  },
  // 方法集合
  methods: {
    ...mapMutations([
      "setLogin",
      "setUserInfo",
      "setLoginDialog",
      "setRegistDialog",
      "setPsdDialog"
    ]),
     scrollToBottom(){
         var t = document.documentElement.scrollHeight+600; 
         console.log(t)
         window.scroll({ top: t, left: 0, behavior: 'smooth' });
     },
     scrollToTop(){
         window.scroll({ top: 0, left: 0});
     },
    logout() {

      this.setLogin(2);
      this.setUserInfo();
      this.$utils.removeItem("token")
      if (this.$route.path.indexOf("/my") >= 0) {
        this.$router.push({
          path: "/",
        });
      }
      
       this.$message.success("退出成功");
    },
    loginDialog() {
      this.setLoginDialog(true);
    },
    registDialog() {
      this.setRegistDialog(true);
    },
    // 顶部头像下拉菜单
    userMenuHandler(type) {
      switch (type) {
        case "user":
          if(this.isLogin==1){
            this.$router.push({
              name: "my",
            });
          }else{
            this.$message.error("请先登录");
          }
         
          break;
        case "set":
           this.setPsdDialog(true)
          break;
        case "quit":
          this.logout();
          break;
      }
    },

    selectMenu(item) {
      if (this.isLogin==1 ||item.indexOf('my')<0) {
        this.scrollToTop();
        this.$router.push({
          path: item,
        });
      } else {
        this.loginDialog();
      }
    },
  },
 
};
</script>
<style scoped lang="scss">
@import "@/styles/mixin.scss";
.el-dropdown-menu__item{
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 5px 20px !important;
   &:hover{
    
    .icon1{
      background: url("~@img/grzx-on.png") no-repeat center;
        background-size: 100% 100%;
    }
    .icon2{ background: url("~@img/xgmm-on.png") no-repeat center;
    background-size: 100% 100%;
    }
     .icon3{
        background: url("~@img/tcdl-on.png") no-repeat center;
       background-size: 100% 100%;
    }
   }
}
.usericon{
  width: 24px;
  height: 24px;
 display: inline-block;
 margin-right:10px;
  &.icon1{
  background: url("~@img/grzx.png") no-repeat center;
    background-size: 100% 100%;

  }
   &.icon2{
  background: url("~@img/xgmm.png") no-repeat center;
    background-size: 100% 100%;

  }
   &.icon3{
  background: url("~@img/tcdl.png") no-repeat center;
    background-size: 100% 100%;
  }
}


.header {
  position: relative;
  z-index: 2;
  box-shadow: 0 2px 10px 0 rgba(52, 52, 52, 0.1);
  background: #fff;
}
.w1660 {
  width: 1660px;
  // width: 85%;
  height: 80px;
  margin: 0 auto;
  position: relative;
}

.wrapper {
  display: flex;
  align-items: center;
}
.logo {
  // width: 80px;
  height: 45px;
  display: block;
}
.title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  line-height: 18px;
  margin-left: 17px;
}
.nav {
  font-size: 20px;
  display: flex;
  margin-left: 120px;
  // margin-right: 150px;

  li {
	position: relative;
    padding-bottom: 4px;
    margin-right: 45px;
    color: #333;
    cursor: pointer;
    border-bottom: 2px solid transparent;

  &.is-active ,&:hover{
      &::after{
        content: '';
        width: 36px;
        height: 4px;
        background-color:  $color-theme;
        position: absolute;
        bottom: -26px;
        border-radius: 2px;
        left: calc(50% - 18px);
      }

    color: $color-theme;
  }
  }

}
.mobile{
	margin-right: 80px;
	color:$color-theme ;
	display: flex;
	justify-content: center;
	align-items: center;
  cursor: pointer;
	img{
		width: 20px;
		height: auto;
	}
	.word{
		margin-left: 10px;
    //
	}
}
@media screen and (max-width: 1800px) {
   .w1660 {
     width:88%;
  }

}
@media screen and (max-width: 1700px) {
   .w1660 {
     width:88%;
  }
  .mobile{
    margin-right: 50px;
  }
  .nav{
    margin-left: 80px;
    li{
      margin-right: 35px;
    }
  }
}
.user-avatar {
  text-align: center;

  .avatar {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    cursor: pointer;
  }
	.user{
		display: flex;
		justify-content: center;
		align-items: center;
    .name{
      max-width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
     white-space: nowrap;
    }
		.headimg{
			width: 32px;
			height: 32px;
			border-radius: 100%;
			overflow: hidden;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.name{
			font-size: 20px;
			margin: 0 10px;
		}
		.arrow{
			margin-top: 2px;
		}
	}
  .el-dropdown {
    padding: 20px 0;

  }
}

.login {
  text-align: center;
 margin-top: 2px;
  .login-btn {
    font-size: 20px;
    cursor: pointer;
	.regist{
		color: #E06B2B ;
		margin-right: 20px;
	}
	.login{
		color:$color-theme;
		position: relative;
		&::before{
			content: '';
			width: 1px;
			height: 20px;
			position: absolute;
			left: -10px;
			top:4px;
			background-color: $color-theme;
		}
	}
  }
}
.rightinfo{
  position: absolute;
  right: 0;
  display: flex;
}
</style>
