<template>
  <div class="footer">
    <div class="w1200 flex-col flex" v-if="Object.keys(obj).length>0" >
      <div class="flex justify-between">
        <div class="flex">
          <img class="piclogo" :src="obj.logo" />
          <!-- <img class="piclogo" src="@img/logo.png" /> -->
          <div class="word flex-col flex">
            <div class="name">{{obj.company_name}}</div>
            <div><img class="piclogo2" src="@img/footer_address.png" /><div class="rightword">地址：{{obj.company_address}}</div></div>
            <div><img class="piclogo2" src="@img/footer_phone.png" /><div class="rightword">电话：{{obj.company_tel}}</div></div>
            <div><img class="piclogo2" src="@img/footer_mobile.png" /><div class="rightword">手机：{{obj.company_phone}}</div></div>
            <div><img class="piclogo2" src="@img/footer_email.png" style="margin-left:0px;width:25px" /><div class="rightword" style="margin-left:12px">邮箱：{{obj.company_email}}</div></div>
          </div>
        </div>

        <div class="codes flex">
          <div class="code flexcenter code1">
            <img class="pic7" :src="obj.mp_qrcode" />
            <div>日安管家小程序</div>
          </div>
          <div class="code flexcenter">
            <img class="pic7" :src="obj.app_qrcode" />
            <div>日安管家APP</div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <span class="txt24">
       {{obj.copyright}}
      </span>
    </div>
  </div>
</template>
  
<script>
export default {
  components: {},
  data() {
    // 这里存放数据
    return {
        obj:{

        }
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  mounted(){
      this.$http.footerinfo().then(res=>{
         this.obj=res.data;
      })
  },
  // 方法集合
  methods: {},
};
</script>
<style scoped lang="scss">
.footer {
  background-color: #fff;
  padding:2px 20px 20px;
}
.piclogo {
  width: 246px;
  height: 71px;
  margin-top: 5px;
}
.piclogo2{
  height: 16px;
  display: block;
  float: left;
}
.rightword{
  margin-left:15px;
}
.word {
  margin-left: 45px;
  font-size: 18px;
  color: #28282C;
  .name {
    font-size:24px;
    color: #333;
    font-weight: bold;
  }
  img{
    width: 20px;
    height: auto;
    display: block;
    margin-left: 3px;
  }
  &>div{
      margin-bottom: 20px;
      overflow: hidden;
      display: flex;
      align-items: center;
  }
}
.codes {
  .code {
    flex-direction: column;
    img{
        margin-bottom: 20px;
        width: 154px;
        height: 154px;
    }
  }
}
.code1 {
  margin-right: 70px;
}
.txt24{
    font-size: 16px;
    text-align: center;
}
</style>
