<template>
  <div class="login">
    <el-dialog
      :visible.sync="loginDialogVisible"
      width="500px"
      :before-close="handleClose"
      class="login-dialog"
      :close-on-click-modal="false"
    >
      <div class="login-wrapper">
        <div class="title">日安企业服务平台</div>
        <el-tabs v-model="activeName"  :stretch="true">
          <el-tab-pane label="账号登录" name="account">
            <el-form
              ref="loginFormRef"
              :model="loginForm"
              :rules="loginFormRules"
            >
              <el-form-item prop="mobile">
                <el-input
                  v-model="loginForm.mobile"
                  placeholder="请输入帐号"
                  type="text"
                  class="input-y"
                  clearable
                >
                  <i slot="prefix" class="">
                    <img src="@img/account.png" alt="" />
                  </i>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  v-model="loginForm.password"
                  placeholder="请输入密码"
                  show-password
                  class="input-y"
                  clearable
                >
                  <i slot="prefix" class="">
                    <img src="@img/pswd.png" alt="" />
                  </i>
                </el-input>
              </el-form-item>
            </el-form>
            <el-checkbox v-model="checked" class="remember">记住密码</el-checkbox
            >
          </el-tab-pane>
          <el-tab-pane label="手机快捷登录" name="phone">
            <el-form
              ref="loginFormRef2"
              :model="loginForm2"
              :rules="loginFormRules2"
            >
              <el-form-item prop="mobile">
                <el-input
                  v-model="loginForm2.mobile"
                  placeholder="请输入手机号码"
                  type="text"
                  class="input-y"
                  clearable
                >
                  <i slot="prefix" class="">
                    <img src="@img/account.png" alt="" />
                  </i>
                </el-input>
              </el-form-item>
              <el-form-item prop="sms_code">
                <div class="flex">
                  <el-input
                    v-model="loginForm2.sms_code"
                    placeholder="请输入验证码"
                    class="input-y"
                    clearable
                  >
                    <i slot="prefix" class="">
                      <img src="@img/yzm.png" alt="" />
                    </i>
                  </el-input>
                  <div class="getcode">
                    <el-button type="primary"  class="getcode"  :class="{ disab: !timeout }">
                      <span v-if="!timeout">{{ time }}秒后重新获取</span>
                      <span v-else @click="getcode">获取验证码</span>
                    </el-button>
                  </div>
                </div>
              </el-form-item>
            </el-form> 
            <div class="remember" > &nbsp;</div>
            <!-- <el-checkbox v-model="checked2" class="remember">下次自动登录</el-checkbox> -->
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">登 录</el-button>
        <div
          class="flex bottom1 justify-between"
          v-if="activeName == 'account'"
        >
          <div class="regist" @click="openregist()">注册账号</div>
          <div class="forget" @click="forgetpsd()">忘记密码?</div>
        </div>
        <div class="flex bottom1 justify-center" v-else>
          <div class="regist" @click="openregist()">注册账号</div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
const defaulttime = 60;
export default {
  components: {},
  data() {
    return {
      activeName: "account",
      time: defaulttime,
      timeout: true,
      timeoutobj: null,
      checked: false,
      checked2: true,
      loginForm: {
        mobile: "",
        password: "",
      },
      loginForm2: {
        mobile: "",
        sms_code: "",
      },
      loginFormRules: {
        mobile: [{ required: true, message: "请输入帐号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      loginFormRules2: {
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            validator: this.validatePhone,
            message: "手机号码不符合",
            trigger: "blur",
          },
        ],
        sms_code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },

  computed: {
    ...mapState(["loginDialogVisible","isLogin"]),
  },
  mounted(){
    var remember=this.$utils.getItemObj("loginForm")
    if(remember){
       this.loginForm=Object.assign(this.loginForm,remember);
       this.checked=true;
    }
     
  },
  methods: {
    ...mapMutations([
      "setLogin",
      "setUserInfo",
      "setLoginDialog",
      "setRegistDialog",
      "setPsdDialog",
    ]),
    rememberpswd(){
      this.$utils.setItemObj("loginForm",this.loginForm);
    },
    validatePhone(rule, value, callback) {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号不正确！"));
      } else {
        callback();
      }
    },
  
    countdown() {
      this.timeoutobj = setTimeout(() => {
        this.time = this.time - 1;
        if (this.time <= 0) {
          clearTimeout(this.timeoutobj);
          this.timeout = true;
        } else {
          this.countdown();
        }
      }, 1000);
    },
    getcode() {
      var phone = this.loginForm2.mobile;
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        this.$message.error({ message: "手机号不正确！!" });
      } else {
        this.$http
          .sendsms({
            mobile: phone,
            event: "quicklogin",
          })
          .then((res) => {
            this.timeout = false;
            this.time = defaulttime;
            this.countdown();
          });
      }
    },
    openregist() {
      this.setLoginDialog(false);
      this.setRegistDialog(true);
    },
    forgetpsd() {
      this.setLoginDialog(false);
      this.setPsdDialog(true);
    },
    handleClose() {
      this.setLoginDialog(false);
    },
    submitForm() {
    
      if (this.activeName == "account") {
        if(this.checked){
          this.rememberpswd();
        }else{
          this.$utils.removeItem("loginForm");
        }
        this.$refs.loginFormRef.validate(async (valid) => {
          if (valid) {
            const res = await this.$http.login(this.loginForm);
            // console.log(res)
            if (res.code !== 1) {
              this.$message.error(res.msg);
            } else {
              this.$utils.setItem("token", res.data.token);
              this.$http.getuserinfo().then((res) => {
                // console.log(res)
                this.setLogin(1)
                this.setUserInfo(res.data);
                this.$message.success({ message: "登录成功!" });
                this.setLoginDialog(false);
              });
            }
          }
        });
      } else {
          this.$refs.loginFormRef2.validate(async (valid) => {
            if (valid) {
              const res = await this.$http.quickloginbymobile(this.loginForm2);
              // console.log(res)
               if (res.code !== 1) {
                this.$message.error(res.msg);
              } else {
                this.$utils.setItem("token", res.data.token);
                this.$http.getuserinfo().then((res) => {
                  this.setLogin(1)
                  this.setUserInfo(res.data);
                  this.$message.success({ message: "登录成功!" });
                  this.setLoginDialog(false);
                });
              }
            }
          })
      }
    },
    async getUserInfo(uid) {
      const { data: res } = await this.$http.getUserInfo({ uid: uid });

      if (res.code !== 200) {
        this.$msg.error(res.msg);
      } else {
        window.sessionStorage.setItem("userInfo", JSON.stringify(res.profile));
        this.setLogin(true);
        this.setUserInfo(res.profile);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.getcode {
  width: 180px;
  padding-left: 20px;
}
.bottom1 {
  font-size: 16px;
  margin-top: 12px;
  .regist {
    color: #1879f5;
    cursor: pointer;
  }
  .forget {
    color: #f51818;
    padding-bottom: 3px;
    border-bottom: 1px solid #f51818;
    cursor: pointer;
  }
}
.remember {
  /deep/.el-checkbox__label {
    font-size: 16px;
    color: #999;
  }
  margin-bottom: 30px;
}
/deep/.el-tabs__header {
  margin-bottom: 35px;
}
.input-y /deep/.el-input__inner {
  background-color: rgba(216, 233, 255, 0.5);
  height: 54px;
  border-radius: 27px;
  border: none;
  padding-left: 60px;
}
/deep/.el-input__suffix {
  right: 10px;
}
/deep/.el-input__prefix {
  margin-top: 13px;
  width: 50px;
}
/deep/.el-tabs__item {
  font-size: 22px;
  color: #999;
  height: 45px;
  //    font-weight: bold;
}
.el-form-item:not(:last-child) {
  margin-bottom: 35px;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: #999;
}
.login-dialog {
  padding: 0 15px;
  .title {
    color: #1879f5;
    font-size: 36px;
    text-align: center;
    margin-bottom: 40px;
  }
  /deep/.el-dialog {
    border-radius: 5px;
  }
  /deep/.el-dialog__body {
    padding: 30px 35px 0;
  }
  /deep/.el-icon-close:before {
    font-size: 30px;
  }

  .dialog-footer {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    padding: 0 15px;
    margin-bottom: 65px;
  }
}

.el-button {
  width: 100%;
  height: 50px;
  font-size: 18px;
  border-radius: 25px;
  &.getcode {
    font-size: 16px;
  }
}
</style>
