export default {
    // 时间毫秒格式化处理 2020-10-30 09:30:00
    formartDate (originVal, fmt) {
        const dt = new Date(originVal)
        const opt = {
            yyyy: dt.getFullYear(),
            MM: (dt.getMonth() + 1 + '').padStart(2, '0'),
            dd: (dt.getDate() + '').padStart(2, '0'),
            HH: (dt.getHours() + '').padStart(2, '0'),
            mm: (dt.getMinutes() + '').padStart(2, '0'),
            ss: (dt.getSeconds() + '').padStart(2, '0')
        }

        for (const k in opt) {
            const ret = new RegExp('(' + k + ')').exec(fmt)
            if (ret) {
                fmt = fmt.replace(ret[1], opt[k])
            }
        }

        return fmt
    },
	transYMD(input) {
		var d = new Date(input)
		var year = d.getFullYear()
		var month = d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : "" + (d.getMonth() + 1)
		var day = d.getDate() < 10 ? "0" + d.getDate() : "" + d.getDate()
		return (year + "-" + month + "-" + day)
	},
	getQueryString(name) {
		var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
		var r = window.location.search.substr(1).match(reg)
		if (r != null) return decodeURI(r[2])
		return null
	},
	setTextLength (text, num = 40){
		if (!text) {
			return false
		}
		const length = text.length
		if (length > num) {
			var str = ""
			str = text.substring(0, num) + "..."
			return str
		} else {
			return text
		}
	},
	setItem(key, value) {
		sessionStorage.setItem(key, value)
	},
	removeItem(key) {
		sessionStorage.removeItem(key)
	},
	getItem(key) {
		return sessionStorage.getItem(key)=="undefined"||sessionStorage.getItem(key)=="null"?false:sessionStorage.getItem(key)
	},
	setItemObj(key, obj) {
		sessionStorage.setItem(key, JSON.stringify(obj))
	},
	getItemObj(key) {
		return JSON.parse(sessionStorage.getItem(key))
	}
   
}
