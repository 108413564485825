<template>
  <div class="login">
    <el-dialog
      :visible.sync="registDialogVisible"
      width="500px"
      :before-close="handleClose"
      class="login-dialog"
      :close-on-click-modal="false"
    >
      <div class="login-wrapper">
        <div class="title">日安企业服务平台</div>
        <el-form ref="form1" :model="loginForm" :rules="loginFormRules">
          <el-form-item prop="mobile">
            <el-input
              v-model="loginForm.mobile"
              placeholder="请输入手机号码"
              type="text"
              class="input-y"
              clearable
            >
              <i slot="prefix" class="">
                <img src="@img/account.png" alt="" />
              </i>
            </el-input>
          </el-form-item>
           <el-form-item prop="nickname">
            <el-input
              v-model="loginForm.nickname"
              placeholder="请输入您的昵称"
              class="input-y"
              clearable
              autocomplete="off"
            >
              <i slot="prefix" class="">
                <img src="@img/nickname.png" alt=""  width="25" />
              </i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              placeholder="请输入密码"
              show-password
              class="input-y"
              clearable
              autocomplete="off"
            >
              <i slot="prefix" class="">
                <img src="@img/pswd.png" alt="" />
              </i>
            </el-input>
          </el-form-item>
          <el-form-item prop="repassword">
            <el-input
              v-model="loginForm.repassword"
              placeholder="请再次输入密码"
              show-password
              class="input-y"
              clearable
              autocomplete="off"
            >
              <i slot="prefix" class="">
                <img src="@img/pswd.png" alt="" />
              </i>
            </el-input>
          </el-form-item>
         
          <el-form-item prop="sms_code">
            <div class="flex">
              <el-input
                v-model="loginForm.sms_code"
                placeholder="请输入验证码"
                class="input-y"
                clearable
              >
                <i slot="prefix" class="">
                  <img src="@img/yzm.png" alt="" />
                </i>
              </el-input>
              <div class="getcode">
                <el-button
                  type="primary"
                  class="getcode"
                  :class="{ disab: !timeout }"
                >
                  <span v-if="!timeout">{{ time }}秒后重新获取</span>
                  <span v-else @click="getcode">获取验证码</span>
                </el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">注 册</el-button>
            <div
              class="flex bottom1 justify-center"
              style="margin-top: 13px; padding-bottom: 30px"
            >
              <el-checkbox v-model="checked" class="remember"
                >我已阅读并同意<span class="blue" @click="openpage('aboutus','ysxy')">《隐私协议》</span>。<span
                  class="blue"  @click="openpage('aboutus','yhfwxy')"
                  >《用户服务协议》</span
                ></el-checkbox
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
const defaulttime = 60;
export default {
  components: {},
  data() {
    return {
      checked: false,
      time: defaulttime,
      timeout: true,
      timeoutobj: null,
      loginForm: {
        mobile: "",
        sms_code: "",
        password: "",
        repassword: "",
        nickname:""
      },
      loginFormRules: {
        mobile: [
          {
            validator: this.validatePhone,
            message: "手机号码不符合",
            trigger: "blur",
          },
        ],
        nickname:[{ required: true, message: "请输入昵称", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        repassword: [{ validator: this.validatePass2, trigger: "blur" }],
        sms_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
    };
  },

  computed: {
    ...mapState(["registDialogVisible"]),
  },
 
  methods: {
    ...mapMutations(["setLogin", "setUserInfo", "setRegistDialog"]),
    validatePhone(rule, value, callback) {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号不正确！"));
      } else {
        callback();
      }
    },
     openpage(path,id){
       this.setRegistDialog(false)
         this.$router.push({
          path,
          query: {
            id,
          },
        })
    },
    validatePass2(rule, value, callback) {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.loginForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    },
    countdown() {
      this.timeoutobj = setTimeout(() => {
        this.time = this.time - 1;
        if (this.time <= 0) {
          clearTimeout(this.timeoutobj);
          this.timeout = true;
        } else {
          this.countdown();
        }
      }, 1000);
    },
    getcode() {
      var phone = this.loginForm.mobile;
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        this.$message.error({ message: "手机号不正确！!" });
      } else {
        this.$http
          .sendsms({
            mobile: phone,
            event: "register",
          })
          .then((res) => {
            this.timeout = false;
            this.time = defaulttime;
            this.countdown();
          });
      }
    },
    handleClose() {
      this.setRegistDialog(false);
    },

    submitForm() {
      if (!this.checked) {
        this.$message.error("请查看并勾选相关协议！");
        return;
      }

      this.$refs.form1.validate(async (valid) => {
        // console.log(this.loginForm);
        if (valid) {
           const res = await this.$http.register(this.loginForm, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (res.code !== 1) {
            this.$message.error(res.msg);
          } else {
            this.$message.success({ message: "注册成功!" });
            this.setRegistDialog(false);
           
          } 
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.getcode {
  width: 180px;
  padding-left: 20px;
}
.disab {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
.remember {
  /deep/.el-checkbox__label {
    font-size: 16px;
    //   color: #999;
  }
  margin-bottom: 30px;
}
/deep/.el-tabs__header {
  margin-bottom: 35px;
}
.input-y /deep/.el-input__inner {
  background-color: rgba(216, 233, 255, 0.5);
  height: 54px;
  border-radius: 27px;
  border: none;
  padding-left: 60px;
}
/deep/.el-input__suffix {
  right: 10px;
}
/deep/.el-input__prefix {
  margin-top: 13px;
  width: 50px;
}

.login-dialog {
  padding: 0 15px;
  .title {
    color: #1879f5;
    font-size: 36px;
    text-align: center;
    margin-bottom: 40px;
  }
  /deep/.el-dialog {
    border-radius: 5px;
  }
  /deep/.el-dialog__body {
    padding: 30px 35px 0;
  }
  /deep/.el-icon-close:before {
    font-size: 30px;
  }

  .dialog-footer {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    padding: 0 15px;
    margin-bottom: 65px;
  }
}

.el-button {
  width: 100%;
  height: 50px;
  font-size: 18px;
  border-radius: 25px;
  &.getcode {
    font-size: 16px;
  }
}
</style>
