import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)

const routes = [{
		path: "/",
		redirect: "/index"
	},
	{
		name: "index",
		component: () => import("./view/index/index.vue"),
		meta: {}
	},{
		name: "qbfw",
		component: () => import("./view/qbfw/index.vue"),
	
	},{
		name: "zxhd",
		component: () => import("./view/zxhd/index.vue"),
	
	},{
		name: "zxdetail",
		component: () => import("./view/zxhd/detail.vue"),
	
	},{
		name: "fwtj",
		component: () => import("./view/fwtj/index.vue"),
	
	},{
		name: "fwdetail",
		component: () => import("./view/fwtj/detail.vue"),
	
	},{
		name: "aboutus",
		component: () => import("./view/aboutus/index.vue"),
	
	},{
		name: "sgal",
		component: () => import("./view/sgal/index.vue"),
	
	},{
		name: "qiye",
		component: () => import("./view/renzhen/qiye.vue"),

	},{
		name: "geren",
		component: () => import("./view/renzhen/geren.vue"),

	}, {
		name: "my",
		redirect:"/my/needs",
		component:  () => import("./view/my/index.vue"),
		children: [{
			name: "我发布的需求",
			path:"/my/needs",
			component:  () => import("./view/my/needs.vue")
		},{
			name: "我的收藏",
			path:"/my/store",
			component: () => import("./view/my/store.vue")
		},{
			name: "意见反馈",
			path:"/my/feedback",
			component: () => import("./view/my/feedback.vue")
		}]
	},{
		name: "fbxq",
		component: () => import("./view/fbxq/index.vue"),
	
	}
]
// 有path就取path，没有就拼接'/' +name
routes.forEach(route => {
	route.path = route.path || "/" + (route.name || "")
})
const router = new VueRouter({
	mode: "hash",
	// base: process.env.BASE_URL,
	routes
})
router.beforeEach((to, from, next) => {
	/* if (to.path === "/login") {
	     next()
	 }else{
	     if(sessionStorage.getItem("userId")){
	         next()
	     }else{
	         next("/login")
	     }   
	 } */
	next();
})
export default router
