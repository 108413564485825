import Vue from "vue"
import App from "./App.vue"
import store from "./store"
import router  from "./router"
import utils from "@/utils/utils"
Vue.config.productionTip = false
Vue.prototype.$utils = utils

import VueAwesomeSwiper from "vue-awesome-swiper"
import "swiper/css/swiper.css"
Vue.use(VueAwesomeSwiper)
import  getApi from "@/apis/http"
Vue.prototype.$http = getApi;

import ElementUI from "element-ui"
import "./styles/index.css";
// import "element-ui/lib/theme-chalk/index.css";

// import "./styles/element-variables.scss"
Vue.use(ElementUI)


new Vue({
	router,
	store,
  render: h => h(App),
}).$mount("#app")
